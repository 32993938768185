<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <Tmap :venueList="venueList" v-if="showMap" :top="80" :type="mapType" :location="myLocation" @mapDetail="mapDetail" @dragends="dragend"></Tmap>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import Tmap from "../components/map";
import { locations } from "../components/js/TMap";
import list from "../components/js/address";

export default {
  name: "HomeView",
  components: {
    Tmap,
  },
  data() {
    return {
      location: {
        lat: 22.556053,
        lng: 99.931218
      },
      myLocation: {
        lat: 22.556053,
        lng: 99.931218
      },
      venueList: [],
      showMap: true,
      mapType: ''
    }
  },
  mounted () {
    this.getLocation()
    console.log('address', list)
    this.venueList = list.address
  },
  methods: {
    getLocation () {
      console.log('1eeee1')
      locations(this.$config.TXMAPKEY).then(qq => {
        var geolocation = new qq.maps.Geolocation(this.$config.TXMAPKEY, 'myapp')
        var options = {timeout: 8000}
        geolocation.getLocation(this.showPosition, this.showErr, options)
        console.log('1eeee1', geolocation)
      })
    },
    mapDetail (id) {
      console.log('ddffghhjkj', id)
      this.$router.push({
        path: '/detail',
        query: {
          id: id
        }
      })
    },
    showPosition (position) {
      // alert(JSON.stringify(position))
      if (position) {
        this.location.lat = position.lat
        this.location.lng = position.lng
        this.location.address = position.addr
        this.myLocation = {
          lat: position.lat,
          lng: position.lng,
          address: position.addr
        }
      }
      this.showMap = true
      console.log('position', position, this.myLocation)
    },
    showErr (err) {},
    dragend (data) {
      // 滑动获取中心坐标
      console.log('dragend', data)
      if (this.location.lat !== data.lat || this.location.lng !== data.lng) {
        this.location.lat = data.lat
        this.location.lng = data.lng
        // this.goMap()
      }
    }
  }
};
</script>
