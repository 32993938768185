<template>
  <div class="map-wrap">
    <div id="container"></div>
    <mt-popup
      v-model="popupVisible"
      style="width:100%;"
      position="bottom">
      <div class="map-box">
        <div class="map-left" style="padding-bottom: 20px;">
          <div class="map-title">{{mapTitle}}</div>
          <div class="map-address" v-if="address">{{distance}} | {{address}}</div>
          <div class="map-address" v-if="types">类型： {{types}}</div>
          <div class="map-address" v-if="num">泊位数量： {{num}}</div>
        </div>
        <!-- <div class="map-right" @click="showMapList" v-if="id">
          <img style="width: 20px; height: 20px; margin-right: 7px;" :src="icons" alt="">
          详情
        </div> -->
      </div>
    </mt-popup>
    <!-- <mt-popup
      v-model="isShowMapList"
      style="width:100%;"
      position="bottom">
      <div class="map-list">
        <span v-for="(item, i) in mapList" :key="i" @click="goMap(item)" :class="{'cancle': i === mapList.length - 1}">{{item.name}}</span>
        <p></p>
        <span class="cancle" @click="isShowMapList = false">取消</span>
      </div>
    </mt-popup> -->
  </div>
</template>

<script>
import {TMaps} from '../components/js/TMap'
export default {
  name: 'Tmap',
  props: {
    venueList: Array,
    location: Object,
    top: Number,
  },
  data () {
    return {
      url: '',
      info: {},
      popupVisible: false,
      icons: require('../assets/detail.png'),
      mapTitle: '信义国际中心', // 位置名称
      address: '福建省-厦门市-思明区-莲前街道环岛东路3911', // 详细地址,
      distance: '606', // 距离
      lat: '',
      lng: '',
      isShowMapList: false,
      mapList: [
        {name: '百度地图'},
        {name: '腾讯地图'},
        {name: '高德地图'}
      ],
      isIos: false,
      isAndroid: false,
      schemeUrl: '',
      downloadUrl: '',
      id: '',
      types: '',
      num: 0,
    }
  },
  watch: {
    venueList (val) {
      this.initMap(val)
    },
  },
  mounted () {
    // this.init()
    var u = navigator.userAgent,
    app = navigator.appVersion
    var isAndroidNum = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    var isIOSNum = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroidNum) {
      // alert('333333vvvvv')
      this.isAndroid = true
    }
    if (isIOSNum) {
      this.isIos = true
    }

    this.initMap(this.venueList)
  },
  methods: {
    initMap (arr) {
      // 默认以数组第一项为中心
      console.log('arr[0]', '1111')
      TMaps(this.$config.TXMAPKEY).then(qq => {
        console.log('ddd', qq)
        var center = new qq.LatLng(this.location.lat, this.location.lng)
        // 创建地图
        var map = new qq.Map(
          document.getElementById('container'),
          {
            center: center,
            zoom: 16,
          }
        )
        console.log('llllll')
        // 提示窗
        // var info = new qq.InfoWindow({
        //   map: map
        // })
        // for (var i = 0; i < arr.length; i++) {
        //   // 为标记添加点击事件
        //   (function (i) {
        //     var data = arr[i]
        //     // 创建标记
        //     var marker = new qq.maps.Marker({
        //       position: new qq.maps.LatLng(data.latitude, data.longitude),
        //       map: map
        //     })
        //     qq.maps.event.addListener(marker, 'click', function () {
        //       info.open()
        //       // 设置提示窗内容
        //       info.setContent('<div><p>' + data.name + '</p></div>')
        //       // 提示窗位置
        //       info.setPosition(new qq.maps.LatLng(data.latitude, data.longitude))
        //     })
        //   }(i))
        // }
        let geometries = [{
          styleId: 'myStyle',
          position: new qq.LatLng(this.location.lat, this.location.lng),
          properties: {
            title: '我的位置',
            address: this.location.address
          }
        }]
        for (let i = 0; i < arr.length; i++) {
          (function (i) {
            geometries.push({
              styleId: 'marker',
              position: new qq.LatLng(arr[i].latitude, arr[i].longitude),
              properties: {
                title: arr[i].name,
                address: arr[i].address,
                place: arr[i].place,
                type: arr[i].type,
                num: arr[i].num,
                id: arr[i]._id
              }
            })
          }(i))
        }
        var markerLayer = new qq.MultiMarker({
          map: map,
          geometries: geometries
        })
        console.log("geometries", geometries)
        markerLayer.on('click', (evt) => {
          console.log('11111', evt.geometry.properties.title, evt)
          this.lat = evt.geometry.position.lat
          this.lng = evt.geometry.position.lng
          this.distance = this.$utils.getDistance(this.lat, this.lng, this.location.lat, this.location.lng)
          this.mapTitle = evt.geometry.properties.title
          this.address = evt.geometry.properties.address
          this.types = evt.geometry.properties.type
          this.num = evt.geometry.properties.num
          this.id = evt.geometry.properties.id || ''
          if (this.mapTitle === '我的位置' && !this.address) {
            this.address = this.mapTitle
          }
          this.popupVisible = true
        })
        let _this = this
        map.on('dragend', function (e) {
          let data = map.getCenter()
          let params = {
            lat: data.lat,
            lng: data.lng
          }
          _this.$emit('dragends', params)
        })

      })
    },
    showMapList () {
      // this.popupVisible = false
      this.isShowMapList = true
      this.$emit('mapDetail', this.id)
    },
    goMap (item) {
      let src = ''
      switch (item.name) {
        case '百度地图':
          src = `http://api.map.baidu.com/marker?location=${this.lat},${this.lng}&title=${this.mapTitle}&content=${this.address}&output=html`
          // src = `baidumap://map/marker?location=${this.lat},${this.lng}&title=${this.mapTitle}&content=${this.mapTitle}&src=ios.baidu.openAPIdemo&coord_type=gcj02`
          break
        case '腾讯地图':
          src = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${this.lat},${this.lng};title:${this.mapTitle};addr:${this.address}&referer=yellowpage`
          break
        case '高德地图':
          src = `https://uri.amap.com/marker?position=${this.lat},${this.lng}&name=${this.mapTitle}`
          // src = `iosamap://navi?sourceApplication=appname&poiname=fangheng&lat=${this.lat}&lon=${this.lng}&dev=1&`
          break
      }
      console.log('src', src)
      window.location.href = src
    },
    openApp() {
      const ua = window.navigator.userAgent.toLowerCase();
      // 非微信浏览器
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        const loadDateTime = +new Date();
        window.setTimeout(function () {
          const timeOutDateTime = +new Date();
          if ((timeOutDateTime - loadDateTime) < 5000) {
            window.location.href = '// ios下载地址';
          } else {
            window.close()
          }
        }, 2000);
        window.location.href = '// ios对应的app协议';
      } else if (navigator.userAgent.match(/android/i)) {
        const state = null;
        try {
          window.location.href = '// 安卓对应的app协议';
          setTimeout(function () {
            window.location.href = '// android下载地址';
          }, 500);
        } catch (e) {
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.map-wrap {
  height: 100vh;
}
#container{
  width:100%;
  height: 100%;
}
.map-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-bottom: env(safe-area-inset-bottom);
  .map-left {
    flex: 1;
    margin-right: 20px;
    .map-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      text-align: left;
      height: 35px;
      line-height: 35px;
    }
    .map-address{
      font-size: 12px;
      text-align: left;
    }
  }
  .map-right{
    background: #3a89e6;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    height: 35px;
    padding:0 10px;
    border-radius: 18px;
  }
}
.map-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: env(safe-area-inset-bottom);
  span {
    height: 40px;
    line-height: 40px;
    width: 100%;
    background: #fff;
    margin-bottom: 1px;
    border-bottom: 1px solid #eee;
  }
  p {
    height: 5px;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #eee;
    display: inline-block;
  }
  .cancle {
    border-bottom: 0;
  }
}
</style>
