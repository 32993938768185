export default {
    address: [
        {
            name: '澜沧县第一人民医院',
            longitude: 99.927356,
            latitude: 22.564163,
            address: '',
            place: '',
            type: 'A类区域',
            num: 195,
            _id: '1'
        },
        {
            name: '县中医医院',
            latitude: 22.56232634609707,
            longitude: 99.93851932670549,
            address: '',
            place: '',
            type: 'A类区域',
            num: 200,
            _id: '2'
        },
        {
            name: '县政府停车场',
            latitude: 22.559053,
            longitude: 99.931418,
            address: '',
            place: '',
            type: 'A类区域',
            num: 120,
            _id: '3'
        },
        {
            name: '县教育科学研究和培训中心住宿区',
            latitude: 22.56331014914677,
            longitude: 99.93611698048183,
            address: '',
            place: '',
            type: 'B类区域',
            num: 35,
            _id: '4'
        },
        {
            name: '县农科局机关办公区及住宿区',
            latitude: 22.55529419986769,
            longitude: 99.92701004268595,
            address: '',
            place: '',
            type: 'B类区域',
            num: 30,
            _id: '5'
        },
        {
            name: '县农经站住宿区',
            latitude: 22.55996767539433,
            longitude: 99.93308116921924,
            address: '',
            place: '',
            type: 'B类区域',
            num: 18,
            _id: '6'
        },
        {
            name: '县农机站办公区和住宿区',
            latitude: 22.55996767539433,
            longitude: 99.93308116921924,
            address: '',
            place: '',
            type: 'B类区域',
            num: 30,
            _id: '7'
        },
        {
            name: '县种子管理站住宿区',
            latitude: 22.55529419986769,
            longitude: 99.92701004268595,
            address: '',
            place: '',
            type: 'B类区域',
            num: 6,
            _id: '8'
        },
        {
            name: '县农业技术推广中心办公区',
            latitude: 22.554874,
            longitude: 99.927057,
            address: '',
            place: '',
            type: 'B类区域',
            num: 18,
            _id: '9'
        },
        {
            name: '县农业技术推广中心住宿区',
            latitude: 22.55529419986769,
            longitude: 99.92701004268595,
            address: '',
            place: '',
            type: 'B类区域',
            num: 16,
            _id: '10'
        },
        {
            name: '县经济作物工作站办公区',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: 'B类区域',
            num: 6,
            _id: '11'
        },
        {
            name: '县外事办住宿区',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: 'B类区域',
            num: 12,
            _id: '12'
        },
        {
            name: '县民政局住宿区',
            latitude: 22.564601,
            longitude: 99.935770,
            address: '',
            place: '',
            type: 'B类区域',
            num: 15,
            _id: '13'
        },
        {
            name: '县地震局',
            latitude: 22.556083,
            longitude: 99.925962,
            address: '',
            place: '',
            type: 'B类区域',
            num: 10,
            _id: '14'
        },
        {
            name: '县人民法院',
            latitude: 22.55720080285402,
            longitude: 99.92543215288802,
            address: '',
            place: '',
            type: 'B类区域',
            num: 90,
            _id: '15'
        },
        {
            name: '扶贫办住宿区',
            latitude: 22.56249839807411,
            longitude: 99.91679176802485,
            address: '',
            place: '',
            type: 'B类区域',
            num: 51,
            _id: '16'
        },
        {
            name: '县公安局住宿区',
            latitude: 22.561533,
            longitude: 99.929888,
            address: '',
            place: '',
            type: 'B类区域',
            num: 57,
            _id: '17'
        },
        {
            name: '县工业商务和信息化局住宿区',
            latitude: 22.556843,
            longitude: 99.930439,
            address: '',
            place: '',
            type: 'B类区域',
            num: 41,
            _id: '18'
        },
        {
            name: '县退役军人事务局办公区域加院内住宿区',
            latitude: 22.559868,
            longitude: 99.935449,
            address: '',
            place: '',
            type: 'B类区域',
            num: 22,
            _id: '19'
        },
        {
            name: '县茶特中心单位范围内',
            latitude: 22.56534792868035,
            longitude: 99.92605062156657,
            address: '',
            place: '',
            type: 'B类区域',
            num: 9,
            _id: '20'
        },
        {
            name: '县市场监督管理局办公区',
            latitude: 22.558923,
            longitude: 99.937831,
            address: '',
            place: '',
            type: 'B类区域',
            num: 12,
            _id: '21'
        },
        {
            name: '澜沧县妇幼保健院',
            latitude: 22.553790,
            longitude: 99.935083,
            address: '',
            place: '',
            type: 'B类区域',
            num: 81,
            _id: '22'
        },
        {
            name: '县气象局住宿区',
            latitude: 22.563508,
            longitude: 99.930937,
            address: '',
            place: '',
            type: 'B类区域',
            num: 15,
            _id: '23'
        },
        {
            name: '县气象局新址',
            latitude: 22.555335,
            longitude: 99.937408,
            address: '',
            place: '',
            type: 'B类区域',
            num: 6,
            _id: '24'
        },
        {
            name: '城市管理综合行政执法局',
            latitude: 22.557151,
            longitude: 99.938873,
            address: '',
            place: '',
            type: 'B类区域',
            num: 18,
            _id: '25'
        },
        {
            name: '原环卫站办公区',
            latitude: 22.559501,
            longitude: 99.937692,
            address: '',
            place: '',
            type: 'B类区域',
            num: 22,
            _id: '26'
        },
        {
            name: '人社局办公区',
            latitude: 22.558819,
            longitude: 99.934559,
            address: '',
            place: '',
            type: 'B类区域',
            num: 39,
            _id: '27'
        },
        {
            name: '人事局住宿小区',
            latitude: 22.560557,
            longitude: 99.937092,
            address: '',
            place: '',
            type: 'B类区域',
            num: 8,
            _id: '28'
        },
        {
            name: '路政管理大队办公区',
            latitude: 22.553523,
            longitude: 99.93596,
            address: '',
            place: '',
            type: 'B类区域',
            num: 6,
            _id: '29'
        },
        {
            name: '县地税务局办公区院内',
            latitude: 22.55823986960728,
            longitude: 99.92695152243571,
            address: '',
            place: '',
            type: 'B类区域',
            num: 81,
            _id: '30'
        },
        {
            name: '市局第三稽查局办公室院内',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: 'B类区域',
            num: 57,
            _id: '31'
        },
        {
            name: '原招待所后老国税住宿区',
            latitude: 22.5598539132835,
            longitude: 99.93289615036913,
            address: '',
            place: '',
            type: 'B类区域',
            num: 7,
            _id: '32'
        },
        {
            name: '检察院大水井住宿区',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: 'B类区域',
            num: 12,
            _id: '33'
        },
        {
            name: '检察院泉兴路住宿区',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: 'B类区域',
            num: 38,
            _id: '34'
        },
        {
            name: '检察院办公区',
            latitude: 22.55606950884675,
            longitude: 99.93805152998843,
            address: '',
            place: '',
            type: 'B类区域',
            num: 62,
            _id: '35'
        },
        {
            name: '澜沧县疾病预防控制中心办公区',
            latitude: 22.573861,
            longitude: 99.913288,
            address: '',
            place: '',
            type: 'B类区域',
            num: 43,
            _id: '36'
        },
        {
            name: '县统战部办公区及住宿区',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: 'B类区域',
            num: 31,
            _id: '37'
        },
        {
            name: '澜沧县搬迁安置办公室',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: 'B类区域',
            num: 20,
            _id: '38'
        },
        {
            name: '县委办',
            latitude: 22.560434,
            longitude: 99.932793,
            address: '',
            place: '',
            type: 'B类区域',
            num: 105,
            _id: '39'
        },
        {
            name: '中共澜沧县委党校住宿区',
            latitude: 22.563505,
            longitude: 99.92947,
            address: '',
            place: '',
            type: 'B类区域',
            num: 21,
            _id: '40'
        },
        {
            name: '统计局办公区',
            latitude: 22.563505,
            longitude: 99.92947,
            address: '',
            place: '',
            type: 'B类区域',
            num: 16,
            _id: '41'
        },
        {
            name: '统计局住宿区',
            latitude: 22.560272,
            longitude: 99.936348,
            address: '',
            place: '',
            type: 'B类区域',
            num: 9,
            _id: '42'
        },
        {
            name: '科学技术协会住宿区',
            latitude: 22.5545200643407,
            longitude: 99.92699205317417,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 98,
            _id: '43'
        },
        {
            name: '动物疫病预防控制中心住宿区',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 42,
            _id: '44'
        },
        {
            name: '市场监督管理局住宿区',
            latitude: 22.555996,
            longitude: 99.928998,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 36,
            _id: '45'
        },
        {
            name: '中国农业银行澜沧县支行住宿区',
            latitude: 22.556877,
            longitude: 99.929340,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 42,
            _id: '46'
        },
        {
            name: '勐朗派出所住宿区',
            latitude: 22.555673,
            longitude: 99.928755,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 28,
            _id: '47'
        },
        {
            name: '和谐家园小区',
            latitude: 22.557712,
            longitude: 99.927249,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 56,
            _id: '48'
        },
        {
            name: '中国邮政银行住宿区',
            latitude: 22.55887113513663,
            longitude: 99.93327308455476,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 25,
            _id: '49'
        },
        {
            name: '税务局第三稽查局住宿区',
            latitude: 22.558383,
            longitude: 99.927193,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 34,
            _id: '50'
        },
        {
            name: '公路家园东朗小区',
            latitude: 22.560270,
            longitude: 99.928441,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 53,
            _id: '51'
        },
        {
            name: '公路小区',
            latitude: 22.55313933829083,
            longitude: 99.9437795487232,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 33,
            _id: '52'
        },
        {
            name: '中国农业银行澜沧东朗支行住宿区',
            latitude: 22.55816164894268,
            longitude: 99.93123041475185,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 28,
            _id: '53'
        },
        {
            name: '烟草公司小区',
            latitude: 22.56057115273789,
            longitude: 99.92854166785249,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 89,
            _id: '54'
        },
        {
            name: '澜沧供电有限公司职工住宿区',
            latitude: 22.560304,
            longitude: 99.929915,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 94,
            _id: '55'
        },
        {
            name: '澜沧县司法局小区',
            latitude: 22.563139,
            longitude: 99.931137,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 53,
            _id: '56'
        },
        {
            name: '财政局住宿区',
            latitude: 22.560434,
            longitude: 99.932793,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 33,
            _id: '57'
        },
        {
            name: '中医药住宿区',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 40,
            _id: '58'
        },
        {
            name: '发改局老住宿区',
            latitude: 22.561114,
            longitude: 99.930448,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 38,
            _id: '59'
        },
        {
            name: '卫生局住宿区',
            latitude: 22.560433,
            longitude: 99.935366,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 56,
            _id: '60'
        },
        {
            name: '建设路六一水务局住宿区',
            latitude: 22.558805,
            longitude: 99.934896,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 28,
            _id: '61'
        },
        {
            name: '澜沧县人事局住宿区',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 47,
            _id: '62'
        },
        {
            name: '国家税务总局澜沧县税务局住宿区',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 52,
            _id: '63'
        },
        {
            name: '农村信用社住宿区',
            latitude: 22.55816164894268,
            longitude: 99.93123041475185,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 109,
            _id: '64'
        },
        {
            name: '工商银行住宿区',
            latitude: 22.55816164894268,
            longitude: 99.93123041475185,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 19,
            _id: '65'
        },
        {
            name: '中国人民银行住宿区',
            latitude: 22.55457142835721,
            longitude: 99.93194505581626,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 90,
            _id: '66'
        },
        {
            name: '政法小区',
            latitude: 22.552390,
            longitude: 99.936505,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 55,
            _id: '67'
        },
        {
            name: '邮政小区',
            latitude: 22.557952,
            longitude: 99.931328,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 32,
            _id: '68'
        },
        {
            name: '烟酒公司小区',
            latitude: 22.557009,
            longitude: 99.930896,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 42,
            _id: '69'
        },
        {
            name: '老街回迁房',
            latitude: 22.553903,
            longitude: 99.930568,
            address: '',
            place: '',
            type: '老旧小区内',
            num: 64,
            _id: '70'
        },
        {
            name: '民族街边疆医院',
            latitude: 22.55517854835282,
            longitude: 99.92259363197755,
            address: '',
            place: '',
            type: 'B类区域',
            num: 81,
            _id: '71'
        },
        {
            name: '拉祜广场',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: 'B类区域',
            num: 99,
            _id: '72'
        },
        {
            name: '澜沧街',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: '',
            num: 0,
            _id: '73'
        },
        {
            name: '东郎路特殊学校旁停车场',
            latitude: 0,
            longitude: 0,
            address: '',
            place: '',
            type: '',
            num: 0,
            _id: '74'
        },
        {
            name: '东郎路县一小旁停车场',
            latitude: 22.56135451857843,
            longitude: 99.92773232786135,
            address: '',
            place: '',
            type: '',
            num: 0,
            _id: '75'
        },
        {
            name: '环城路志寰驾校旁停车场一',
            latitude: 22.57085001645314,
            longitude: 99.91366502176997,
            address: '',
            place: '',
            type: '',
            num: 0,
            _id: '76'
        },
        {
            name: '环城路志寰驾校旁停车场二',
            latitude: 22.5718680758858,
            longitude: 99.91429845974318,
            address: '',
            place: '',
            type: '',
            num: 0,
            _id: '77'
        },
        {
            name: '小桥头公测停车场',
            latitude: 22.55019121086952,
            longitude: 99.92567713868993,
            address: '',
            place: '',
            type: '',
            num: 0,
            _id: '78'
        },
        {
            name: '信访局',
            latitude: 22.55896504478565,
            longitude: 99.9308539923493,
            address: '',
            place: '',
            type: '',
            num: 0,
            _id: '79'
        },
        {
            name: '不知名停车场',
            latitude: 22.55065290029475,
            longitude: 99.9254872805446,
            address: '',
            place: '',
            type: '',
            num: 0,
            _id: '80'
        },
    ]
}