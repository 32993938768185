
export function dateTime (val, type) { // 年月日时分
  let date = new Date(val)
  let year = date.getFullYear()
  let monthVal = date.getMonth() + 1
  let month = monthVal < 10 ? '0' + monthVal : monthVal
  let dayVal = date.getDate()
  let day = dayVal < 10 ? '0' + dayVal : dayVal
  let HoursVal = date.getHours()
  let hours = HoursVal < 10 ? '0' + HoursVal : HoursVal
  let minuteVal = date.getMinutes()
  let minute = minuteVal < 10 ? '0' + minuteVal : minuteVal
  let secondVal = date.getSeconds()
  let second = secondVal < 10 ? '0' + secondVal : secondVal
  let dateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minute
  if (type === 'second') {
    dateTime = dateTime + ':' + second
  }
  if (type === 'date') {
    dateTime = year + '-' + month + '-' + day
  }
  return dateTime
}

export function timeDifference (timeType, oldDate) { // 计算时间差
  let thisDate = new Date
  oldDate = new Date(oldDate)
  let time = ''
  let typeText = ''
  // timeType 0 计算已过时间 1 计算剩余时间
  if (timeType === 0) {
    typeText = '前'
    time = thisDate.getTime() - oldDate.getTime()
  } else {
    time = oldDate.getTime() - thisDate.getTime()
  }
  // 计算天数
  let day = Math.floor(time / (24 * 3600 * 1000))
  if (day > 30) {
    if (timeType === 0) {
      return dateTime(oldDate)
    }
  }

  if (day > 0) {
    return day + '天' + typeText
  }
  // 计算小时
  var leave1 = time % (24 * 3600 * 1000)
  var hours = Math.floor(leave1 / (3600 * 1000))
  if (hours > 0) {
    return hours + '小时' + typeText
  }
  // 计算分钟
  var leave2 = leave1 % (3600 * 1000)
  var minutes = Math.floor(leave2 / (60 * 1000))
  if (minutes > 0) {
    return minutes + '分钟' + typeText
  }
  // 计算秒
  var leave3 = leave2 % (60 * 1000)
  var second = Math.round(leave3 / 1000)
  if (second > 4) {
    return second + '秒' + typeText
  }
  if (second < 5 && timeType === 0) {
    return '刚刚'
  }
  if (second <= 0 && timeType === 1) {
    return '已结束'
  }
}

export function deep (params) { // 深拷贝
  if (params === '') {
    return ''
  }
  let obj = {}
  obj = JSON.parse(JSON.stringify(params))
  return obj
}

export function Message (type, message) { // 消息提醒
  // type => {
  //   普通: '';
  //   成功: success;
  //   警告: warning;
  //   错误: error;
  // }
  this.$message({
    showClose: true,
    message: message,
    type: type
  })
}

export function storage (type, key, val) { // 本地缓存
  let data = {}
  switch (type) {
    case 'set':
      localStorage.setItem(key, val)
      break
    case 'get':
      data = localStorage.getItem(key)
      break
    case 'remove':
      localStorage.removeItem(key)
      break
  }
  return data
}

export function regular (type, val) { // 正则验证
  let match = ''
  switch (type) {
    case '0': // 手机号,座机号
      // match = /^1[34578]\d{9}$/
      match = /(^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$)|(^((0\d{2,3}))(\d{7,8})(-(\d{3,}))?$)|(^1[3456789]\d{9}$)|(^((4\d{2,3})-)((\d{3,4})-)(\d{3,4})$)/
      break
    case '1': // 身份证
      match = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      break
    case '2': // 邮箱
      match = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
      break
    case '3': // 姓名
      match = /^[\u4E00-\u9FA5\uf900-\ufa2d]{2,4}$/
      break
  }
  var result = match.test(val)
  if (result) {
    return true
  } else {
    return false
  }
}

// 浮点数减法运算
export function FloatSub (arg1, arg2, type) {
  var r1, r2, m, n
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  // 动态控制精度长度
  n = (r1 === r2) ? r1 : r2
  if (type === 'add') {
    return ((arg1 * m + arg2 * m) / m).toFixed(n)
  }
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}
function accMul (arg1, arg2) {
  var m = 0
  var s1 = arg1 ? arg1.toString() : '0.00'
  var s2 = arg2 ? arg2.toString() : '0.00'
  try {
    m += s1.split('.')[1].length
  } catch (e) {}
  try {
    m += s2.split('.')[1].length
  } catch (e) {}
  return Number(s1.replace('.', '')) * Number(s2.replace('.', ''))/Math.pow(10,m)
}

// 生成包含字母大小写的随机数
function verificationCode () {
  var arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  var rand1 = Math.floor((Math.random() * 62))
  var rand2 = Math.floor((Math.random() * 62))
  var rand3 = Math.floor((Math.random() * 62))
  var rand4 = Math.floor((Math.random() * 62))
  var rand5 = Math.floor((Math.random() * 62))
  var rand6 = Math.floor((Math.random() * 62))
  var value = '' + arr[rand1] + arr[rand2] + arr[rand3] + arr[rand4] + arr[rand5] + arr[rand6]
  // alert('验证码为: ' + ' ' + arr[rand1] + ' ' + arr[rand2] + ' ' +  arr[rand3] + ' '  + arr[rand4] + ' '  + arr[rand5] + ' '  + arr[rand6])
  // document.write('验证码为: ' + arr[rand1] + arr[rand2] + arr[rand3] + arr[rand4]);
  console.log(value)
  return value
}

function formatDate (date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  function padLeftZero (str) {
    return ('00' + str).substr(str.length)
  }
  for (var k in o) {
    var str = o[k] + ''
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}

function swapArray (arr, index, type) {
  arr.splice(type ? index : index - 1, 1, ...arr.splice(type ? index + 1 : index, 1, arr[type ? index : index - 1]))
  console.log('ddddd', arr)
  return arr
}
function getDistance (lat1, lng1, lat2, lng2) { // 获取两点坐标的距离(单位：km)
  lat1 = Number(lat1 || 0)
  lng1 = Number(lng1 || 0)
  lat2 = Number(lat2 || 0)
  lng2 = Number(lng2 || 0)
  var rad1 = lat1 * Math.PI / 180.0;
  var rad2 = lat2 * Math.PI / 180.0;
  var a = rad1 - rad2;
  var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
  var r = 6378137;
  let val = (r * 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(rad1) * Math.cos(rad2) * Math.pow(Math.sin(b / 2), 2)))).toFixed(0)
  console.log('val', val)
  if (val < 1000) {
    return val + 'm'
  }
  val = (val / 1000).toFixed(1)
  return val + 'km'
}

export default {
  dateTime,
  deep,
  storage,
  regular,
  Message,
  timeDifference,
  FloatSub,
  accMul,
  verificationCode,
  formatDate,
  swapArray,
  getDistance
}
