let IP;

/**
 * 测试
 */
if (process.env.NODE_ENV === "production") {
  IP = "https://api.ilinzhi.cn/";
} else {
  IP = "http://192.168.1.131:9124/";
}

module.exports = {
  IP: IP,
  BASE_URL: IP + "api/",
  SESSION_ID_OVERDUE_VALUE: -1, // session_id过期的
  REQUEST_CONTENT_TYPE: "application/x-www-form-urlencoded",
  APP_SECRET: "2e9627aa9cc8210bb0fa01ec459b3c22", // 密钥
  TEMPLATE_ID: "KrW4MWIKz4BpHTW1d6Fkieqz74DanPhHKU6NCLq6wYY", // 模板ID
  SUCCESS_CODE: "000001",
  TXMAPKEY: "2BEBZ-SU26R-6KKW4-WXVBB-QQ42Q-NHF3V", // 腾讯地图key
  FILE_URL: IP + "upload/files",
  MAX_IMAGE_SIZE: 10,
  TEMP_IMAGE_URL: IP + "uploads/image/temp/",
  pre_image_url: `${IP}upload/image`,
};
