import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "./config";
import utils from './utils';
import 'mint-ui/lib/style.css'

import { Progress, Popup, Toast } from "mint-ui";
import Vant from "vant";

Vue.config.productionTip = false;
Vue.component(Progress.name, Progress);
Vue.component(Popup.name, Popup);
Vue.prototype.$toast = Toast;
Vue.prototype.$config = config;
Vue.prototype.$utils = utils;

Vue.use(Vant);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
