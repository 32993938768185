export function TMaps (key) {
  return new Promise(function (resolve, reject) {
      window.init = function () {
        resolve(TMap)//注意这里
      }
      var script = document.createElement("script");
      script.type = "text/javascript";
      // script.src = "https://map.qq.com/api/js?v=2.exp&callback=init&key="+key
      script.src = "https://map.qq.com/api/gljs?v=1.exp&callback=init&key="+key
      script.onerror = reject;
      document.head.appendChild(script);
  })
}

export function locations (key) {
  return new Promise(function (resolve, reject) {
      window.onload = function () {
        console.log('23mmm', qq)
        resolve(qq)//注意这里
      }
      var script = document.createElement("script");
      script.type = "text/javascript";
      // script.src = "https://map.qq.com/api/js?v=2.exp&callback=init&key="+key;
      script.src = "https://mapapi.qq.com/web/mapComponents/geoLocation/v/geolocation.min.js"
      script.onerror = reject;
      document.head.appendChild(script);
      console.log('213132131', document.head)
  })
}
